/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/


.header {	

	.clearHeader {

        > .row {min-height: 120px; @include transition(all 0.3s ease-in-out);}

        #logo {
            width:250px; height:110px;
		    background-size: 250px 110px; 
            @include transition(all 0.3s ease-in-out);
        }
		
	}
	 
	.darkHeader {

        > .row {min-height:110px; @include transition(all 0.3s ease-in-out);}

        #logo {
             width:215px; height:95px;
		    background-size: 215px 95px; 
            @include transition(all 0.3s ease-in-out);
        }

	}
	
	
}

// HOME TEMPLATE 2
body.Template-2 {
}

// HOME TEMPLATE 3
body.Template-3 {
}

// HOME TEMPLATE 4



#content {height:auto;}
