/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly {display:none!important;}
.desktopOnly {display:inline-block!important;}


/*sizing*/
h1, .h1 { font-size: 2.2em; }
h2, .h2 { font-size: 2em; }
.bigger, h3, .h3, [data-control] {font-size:1.2em;}
#message {font-size:1em;}

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div, body.Home #content #leftcol > div > div {
    padding: 2em 0;
}

/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/*Increase padding content some rows */

/* Restrict width on some rows and content areas that behave like rows*/

		
/*********************
SPRITE
*********************/
		

    #logo {
        width:200px; height: 88px;
	    background-size: 200px 88px;
	}

/*********************
HERO STYLES
*********************/
body.Template-2, body.Template-3 {
    #hero {
        height: 100vh;
    }
}

body:not(.Home):not(.home) #hero {
	.block {
        max-width: 1400px;
        //position small circle heading/links
        .heading {
            text-align: right; 
            > picture {
                margin-right:1em;
            }
        }

    }
}/*hero*/



/*body.Home .header {
	height: 112.09px;
}*/

.circleIcons {
    width:125px;
    height:125px;
}


/*.circleimage {
    top: 12em;
    left: 3em;
}*/

/*body:not(.Home):not(.home) #hero {


	.heading > picture { padding: 5em;}

}*/

/*********************
HEADER STYLES
*********************/

.header {



	    .row {
		    position: relative;
            min-height: 110px;
	    }

	    #logo, #accountMenu {
            transform: none;
	    }

        #logo {
            left: 0%;
            top:0.5em;
        }

	    #accountMenu {
			top: 6%;
            right:0;
	    }

        #mainMenu {
            display: inline-block;
            position: absolute;
            right: 0%;
            bottom:20%;
        }
     		
}


// HOME TEMPLATE 3

body.Template-3 {
    .header {
        #accountMenu {
            transform: none;
			top: 10%;
            right:0;
	    }
    }
}





/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}


.topMenu {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    float: right;
    clear: none;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;
        display: inline;


        > li {
            display: inline-block;
            padding: 0 0.5em;

            > a, > a.parent {
                outline: none;
                position: relative;
                background: transparent;
                padding: 0.25em 0em;

                &:hover, &:focus {
                    background: transparent;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: $color-secondary;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    @include transition(all 0.3s ease-in-out 0s);
                }

                &:hover:after, &.activerootmenulink:after {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
            /*Prominent contact link*/
            &:first-child {
                /*display: none;*/
            }
            /*&:last-child {
                > a {
                    background: $color-secondary;
                    @include border-radius($small-border-radius);
                    padding: 0.5em 1em;
                    @include transition(all 0.5s ease-in-out);

                    &:hover, &:focus, &:active, &.activerootmenulink {
                        background: darken($color-secondary, 15%);
                        @include transition(all 0.5s ease-in-out);
                        color: $white;
                    }
                }
            }*/
            /*turn some of the main nav items OFF for public*/
            /*&:first-child > a{
				display:none;
			}
			
			&:nth-child(6) {
				display:none;
			}
			&:nth-child(7) {
				display:none;
			}
			*/
        }

        li {
            position: relative;

            a {
                
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-primary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 250px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/




/*********************
CONTENT STYLES
*********************/

.col-2 {
    > div, > li {
        grid-column: span 1;
    }
}

/*********************
BLOCK LAYOUT
**********************/


.columnlayout:not(.columnlayout.services) {
    > div, > li, > article {
        width: calc(50% - 1em);
        padding: 1em;
        height: 9em;
    }

    article.newsBlock {
        width: 25%;
    }
}

.columnlayout.services {
    > div, > li, > article {
        width: calc(33% - 1em);
        padding: 1em;
    }

    article.newsBlock {
        width: 25%;
    }
}
#gallery li {width: 25%;}



/* LEFT BLOCKS */


.circleimage {
    
    height:350px;

    > p {
        
        text-align:right;

    }
}

.circleImage{
    width:350px;
    height:350px;
}

/*********************
FOOTER STYLES
*********************/

#footer {
    background: $color-primary url('../images/background.svg') no-repeat center right 50px;
    background-size: 300px 300px;
}

     body.Template-2 {
        #bottomBlocks {
            .respond-form {padding-top:1em;}
        }
        #footer {
            .last {text-align:right;}
        }
    }

     body.Template-3 {
        #footer {
            .last {text-align:right;}
        }
    }

    /*Bottom Blocks*/

    #bottomBlocks .block {
        margin-bottom: 2em;
        margin: 0 auto;
    }

    /*layout needed for list items */
    #bottomBlocks #block_26 {

        ul {
           
            li {
                display:inline-block;
                width: 48%;
                margin-bottom: 0.5em;

            }
        }

    }

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */  

/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

ul.responsive3 {
	max-height: none;
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				/*height:500px;*/
				
				&:before {background-color: rgba(0, 0, 0, 0.05); }
				
				img {
                        /*height: 420px;*/
                    }	
				.slideshow_caption { text-align:left; /*margin: 0 auto;padding:2em; max-width:50%;*/ }
			}
				
		}
			
	}

    .slick-slider .slick-active img{ margin-left: 0;}

    .slick-prev, .slick-next { top: 50%; }
    .slick-next { right: 0px; }
    .slick-prev { left: 0; }
    .slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }
}

// HOME TEMPLATE 2
body.Template-2, body.Template-3, body.Template-4 {
   .slick-list {
			
		.slick-track {
				
			.slick-slide {
	
				.slideshow_caption { text-align:center; }
			}
				
		}
    }
}



/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }

/*********************
Sticky CTA
*********************/

#stickyCTA {
	 
}