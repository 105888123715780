/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
h1, .h1  { line-height: 1.2;}

/*margins*/
h2, .h2, .gmb-review p{ margin: 0.5em 0;}
#bottomBlocks h4, #bottomBlocks .h4 { margin:  2em 0 1em 0;}

/*sizing*/
h1, .h1 { font-size: 3em; }
.caption h1 {font-size: 3.25em;}
body.landing .h1 { font-size: 3em; }
h2, .h2 { font-size: 2.1em; }
#accountMenu > a:first-of-type {font-size:1.2em;}
.caption h3 {font-size:1.5em;}


.topMenu .nav > li > a {
	@include bp(papa-screen) {
		font-size: 120%;
	}
}

/*********************
LAYOUT & GRID STYLES
*********************/

.row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div, body.Home #content #leftcol > div > div {
	padding: 1em 0;
}
	
/*********************
HEADER STYLES
*********************/


// HOME TEMPLATE 3
body.Template-3 {
	.header {
		#mainMenu {
			display: inline-block;
			position: absolute;
			right: 0%;
			bottom: 20%;
		}

		#accountMenu {
			right: 0%;
            top: unset;
			bottom: 20%;
		}
	}
}




/**************************
HERO - Home
***************************/
/*body.Home #hero {max-height:75vh;}*/
body:not(.Home):not(.home) #hero {
	/*height: 90vh;*/
	overflow: hidden;

	.block > p img:not(.heading img) {
		min-width: 100%;
		max-width: initial;
		height: auto;
	}
}


// LB overwrites
body:not(.Home):not(.home) #hero {
    .block {
        //position small circle heading/links
        .heading {
            > picture {
                 margin-right:2em;
            }
        }
    }
}

body.Home #hero .row .slideshow_caption {
    width: 40%;
}

body.Home #hero {
	.caption {
		width: 85%;
		left: 50%;
		
	}
}

/*body:not(.Home):not(.home) #hero .heading {
	top: 15%;
}*/



/*********************
NAVIGATION STYLES
*********************/

.topMenu {		
	.nav {		
		> li {
			padding: 0 1em;
		}
	}
}




/*********************
CONTENT STYLES
*********************/

#content #leftcol > div > .first {padding:0 2em;}

.columnlayout.services, .columnlayout.pillars {
	flex-wrap: nowrap;
	flex-direction: row;
}

/*********************
BLOCK LAYOUT
**********************/


	.columnlayout {

		> div, li {
		}
		/*Top row needs border adding seperatley to prevent double borders*/
		> div:nth-child(4), li:nth-child(4) {
		}
	}


	#bottomBlocks {
		.block {
			margin-bottom: 0em;
		}
	}
	/* LEFT BLOCKS - Mission statement*/
	#homeMission {
		.row {
			height: 60vh;
			display: flex;
			align-items: center;
		}
	}
	/*Full width slider */
	ul.responsive3 {

		.slick-list {
			.slick-track {
				.slick-slide {

					img {
					}
				}
			}
		}
	}

	@media (min-width: 1600px) {
		.columnlayout:not(.columnlayout.services) {
			> div, > li, > article {
				width: calc(50% - 1em);
				padding: 1em;
				height: 11em;
			}
		}

		.circleIcons {
			width: 150px;
			height: 150px;
		}

		.circleimage {
			/*top:18em;*/
			height: 400px;
		}

		.circleImage {
			width: 400px;
			height: 400px;
		}
	}